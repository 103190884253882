<template> 
    <ul class="list-group border-0 d-flex flex-column">
    <li class="list-group-item border-0">
        <div class="d-flex flex-column w-100 gap-2">
            <OContentEditable v-model="checklist.name.Name" class="fw-bold text-muted form-control border-0 pe-1 p-0" ref="checklistNameInput"
                    @focus="isEditingName = true"
                    @blur="isEditingName = false"/>
            <div v-if="isEditingName || checklist.name.hasChanges || isNameEmpty || checklist.isNewRecord" ref="checklistButtonContainer" :key="updateAfterSaveKey" class="align-self-start d-flex flex-row gap-2 w-100">
                <button type="button" :class="{'disabled': (!checklist.name.hasChanges || isNameEmpty) && !checklist.isNewRecord}" class="btn btn-primary btn-sm text-nowrap" 
                    @mousedown.prevent="" @mouseup="saveNameChanges" v-text="checklist.isNewRecord ? 'Create' : 'Save changes'">
                </button>
                <button type="button" class="btn btn-secondary btn-sm" 
                    @mousedown.prevent="" @mouseup="checklist.isNewRecord ? deleteChecklist() : cancelNameChanges()">
                    Cancel
                </button>
                <button v-if="!checklist.isNewRecord" class="btn btn-outline-primary py-0 px-2 opacity-75 ms-auto"
                    @mousedown.prevent="" @mouseup="deleteChecklist">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
        </div>
        
        <ul v-if="!checklist.isNewRecord" v-for="checklistItem in checklist.checklistItems" class="list-group-item border-0">
            <checklist-item :checklistItem="checklistItem"
                @saveChecklistItem="saveChecklistItem(checklistItem.index)"
                @deleteChecklistItem="deleteChecklistItem(checklistItem.index)"/>
        </ul>
        <button v-if="!checklist.isNewRecord" class="btn btn-sm btn-primary me-auto mt-2 ms-2" @click="createChecklistItem">Add an Item</button>
    </li>
    </ul>
</template>

<script setup>
    import { getDataObjectById } from 'o365.vue.ts'
    import { ref, computed, onMounted } from 'vue'
    import ChecklistItem from 'o365.vue.components.Checklists.ChecklistItem.vue'; 
    import DataItem from 'o365.modules.DataObject.Item.ts';
    import { selectWholeText } from 'common.js';
    import o365_confirm from 'o365.controls.confirm.ts';

    const props = defineProps({
        checklist: {
            type: Object,
            required: true
        },
        itemRow: {
            type: Object,
            required: true
        }
    });
    const emit = defineEmits(["saveChecklist", "deleteChecklist"])

    const isEditingName = ref(null);
    const checklistButtonContainer = ref(null);
    const checklistNameInput = ref(null);
    const isNameEmpty = computed(() => { return !props.checklist.name.Name })

    onMounted(() => {
        if(props.checklist.isNewRecord) {
            let checklistName = checklistNameInput.value.$el;
            checklistName.focus();
            selectWholeText(checklistName);
        }
    })

    const updateAfterSaveKey = ref(false);
    function saveNameChanges() {
        props.checklist.isNewRecord = false;
        props.checklist.name.commitChanges(0);
        checklistButtonContainer.value?.classList.add('disabled')
        document.activeElement.blur();
        emit("saveChecklist");
        updateAfterSaveKey.value = !updateAfterSaveKey.value;
    }

    function cancelNameChanges() {
        document.activeElement.blur();
        props.checklist.name.cancelChanges();
    }

    function createChecklistItem() {
        const item = new DataItem(props.checklist.checklistItems.length, {
            Name: "",
            value: false,
        }, {});
        item.isNewRecord = true;
        props.checklist.checklistItems.push(item);
    }

    function saveChecklistItem(index) {
        if(!index) {
            emit("saveChecklist");
            return;
        } 

        emit("saveChecklist");
    }

    function deleteChecklist() {
        document.activeElement.blur();
        emit("deleteChecklist");
    }

    function deleteChecklistItem(index) {
/*
        const options = {
            message: 'Are you sure you want to delete this Item?',
            title: 'Delete confirm',
            btnTextOk: 'Delete',
            btnClassOk: 'btn-danger',
            zIndex: 1060
        };
        o365_confirm(options).then(() => {
*/
            props.checklist.checklistItems.splice(index, 1);

            props.checklist.checklistItems.forEach((checklistItem, idx) => {
                checklistItem.index = idx;
            });
            emit("saveChecklist");
/*
        }).catch(_ => {});
*/
    }
</script>